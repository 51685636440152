<template>

    <div class="human-body">

        <div class="content">
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>

        <div class="orientation"><img src="@/assets/images/global/img-orientation.png"></div>

    </div>

</template>


<script>

export default {

    name: 'HumanBody',

    metaInfo() {
        return {
            title: 'Effects of Altitude',
            meta: [
                {
                    name: 'description',
                    content: 'See how Everest\'s high altitudes affect the climbers who attempt to reach the summit.'
                }, {
                    name: 'keywords',
                    content: 'national geographic, natgeo, everest, mt everest, mt. everest, mount everest, climbing, summit, summiting, explore, oxygen, human, body, altitude, height, sea level'
                }, {
                    property: 'og:title',
                    content: 'Effects of Altitude'
                }, {
                    property: 'og:type',
                    content: 'website'
                }, {
                    property: 'og:url',
                    content: process.env.VUE_APP_BASE_URL + 'shares/effects-of-altitude.html'
                }, {
                    property: 'og:image',
                    content: process.env.VUE_APP_BASE_URL + 'img/shares/Everest_metaimage-facebook_v01_ls_0002_HumanBody.jpg'
                }, {
                    property: 'site_name',
                    content: 'National Geographic : Everest Educational Interactives'
                }, {
                    property: 'og:description',
                    content: 'See how Everest\'s high altitudes affect the climbers who attempt to reach the summit.'
                }, {
                    property: 'twitter:card',
                    content: 'summary_large_image'
                }, {
                    property: 'twitter:url',
                    content: process.env.VUE_APP_BASE_URL + 'shares/effects-of-altitude.html'
                }, {
                    property: 'twitter:title',
                    content: 'Effects of Altitude'
                }, {
                    property: 'twitter:description',
                    content: 'See how Everest\'s high altitudes affect the climbers who attempt to reach the summit.'
                }, {
                    property: 'twitter:image',
                    content: process.env.VUE_APP_BASE_URL + 'img/shares/Everest_metaimage-twitter_2-1_v01_ls_0002_HumanBody.jpg'
                }
            ]
        };
    },

    components: {
        
    },

    data() {
        return {
            componentName: this.$options.name + ' View'
        };
    },

    created() {

    },

    mounted() {

    },

    methods: {

    }

}

</script>


<style lang="scss" scoped>

.human-body {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & .content {
        display: block;
        background: linear-gradient(to bottom,  #dbebf6 0%,#ffffff 50%);
        position: absolute;
        width: 100%;
        height: 100%;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
            display: none;
        }
    }

    & .orientation {
        position: fixed;
        display: none;
        width: 100%;
        height: calc(100vh - 100px);
        width: 100vw;
        height: 100%;
        z-index: 100;
        transform: translateZ(10001px);
        object-fit: contain;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column nowrap;
        }

        & img {
            height: 100%;
            object-fit: contain;
        }

    }
}

</style>
